import { createContextFactory } from "@devowl-wp/utils";
import { OptionStore, PluginUpdateStore } from ".";
/**
 * A collection of all available stores which gets available
 * through the custom hook useStores in your function components.
 *
 * @see https://mobx.js.org/best/store.html#combining-multiple-stores
 */ class RootStore {
    get context() {
        return this.contextMemo ? this.contextMemo : this.contextMemo = createContextFactory(this);
    }
    constructor(){
        this.optionStore = new OptionStore(this);
        this.pluginUpdateStore = new PluginUpdateStore(this);
    }
    static get StoreProvider() {
        return RootStore.get.context.StoreProvider;
    }
    static get get() {
        return RootStore.me ? RootStore.me : RootStore.me = new RootStore();
    }
}
const useStores = ()=>RootStore.get.context.useStores();
export { RootStore, useStores };
