import { _ as _ts_decorate } from "@swc/helpers/_/_ts_decorate";
import { _ as _ts_metadata } from "@swc/helpers/_/_ts_metadata";
import { observable, runInAction } from "mobx";
import { BaseOptions } from "@devowl-wp/utils";
class OptionStore extends BaseOptions {
    constructor(rootStore){
        super();
        this.rootStore = rootStore;
        this.pureSlug = BaseOptions.getPureSlug(process.env.slug);
        this.pureSlugCamelCased = BaseOptions.getPureSlug(process.env.slug, true);
        // Use the localized WP object to fill this object values.
        runInAction(()=>Object.assign(this, window[this.pureSlugCamelCased]));
    }
}
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], OptionStore.prototype, "others", void 0);
export { OptionStore };
