import { _ as _ts_decorate } from "@swc/helpers/_/_ts_decorate";
import { _ as _ts_metadata } from "@swc/helpers/_/_ts_metadata";
import { observable, set, flow, computed, action } from "mobx";
import { locationRestPluginUpdatePatch, locationRestPluginUpdateSkipPost, locationRestAnnouncementActive } from "../wp-api";
import { request } from "../utils";
import { License } from ".";
class PluginUpdate {
    get licensedEntries() {
        return this.licenses.filter((param)=>{
            let { code } = param;
            return code;
        });
    }
    get unlicensedEntries() {
        return this.licenses.filter((param)=>{
            let { code } = param;
            return !code;
        });
    }
    get noUsageEntries() {
        return this.unlicensedEntries.filter((param)=>{
            let { noUsage } = param;
            return noUsage;
        });
    }
    get modifiableEntries() {
        return this.unlicensedEntries.filter((param)=>{
            let { programmatically } = param;
            return !programmatically;
        });
    }
    get isLicensed() {
        return this.unlicensedEntries.length === 0;
    }
    constructor(pluginUpdate, store){
        this.busy = false;
        this.additionalCheckboxes = [];
        this.showBlogName = false;
        this.showNetworkWideUpdateIssueNotice = false;
        this.setAnnouncementActive = flow(function*(state) {
            this.busy = true;
            try {
                const result = yield request({
                    location: locationRestAnnouncementActive,
                    params: {
                        slug: this.slug
                    },
                    request: {
                        state
                    }
                });
                if (result.success) {
                    this.announcementsActive = state;
                }
                return result.success;
            } catch (e) {
                console.log(e);
                throw e;
            } finally{
                this.busy = false;
            }
        });
        this.update = flow(function*(data) {
            this.busy = true;
            this.invalidKeysError = undefined;
            try {
                const result = yield request({
                    location: locationRestPluginUpdatePatch,
                    params: {
                        slug: this.slug
                    },
                    request: data
                });
                this.fromResponse(result);
            } catch (e) {
                var _e_responseJSON_data, _e_responseJSON;
                console.log(e);
                // Parse error for invalid keys
                if ((_e_responseJSON = e.responseJSON) == null ? void 0 : (_e_responseJSON_data = _e_responseJSON.data) == null ? void 0 : _e_responseJSON_data.invalidKeys) {
                    this.invalidKeysError = e.responseJSON.data.invalidKeys;
                }
                throw e;
            } finally{
                this.busy = false;
            }
        });
        this.skip = flow(function*() {
            this.busy = true;
            try {
                yield request({
                    location: locationRestPluginUpdateSkipPost,
                    params: {
                        slug: this.slug
                    }
                });
            } catch (e) {
                var _e_responseJSON_data, _e_responseJSON;
                console.log(e);
                // Parse error for invalid keys
                if ((_e_responseJSON = e.responseJSON) == null ? void 0 : (_e_responseJSON_data = _e_responseJSON.data) == null ? void 0 : _e_responseJSON_data.invalidKeys) {
                    this.invalidKeysError = e.responseJSON.data.invalidKeysError;
                }
                throw e;
            } finally{
                this.busy = false;
            }
        });
        this.fromResponse(pluginUpdate);
        this.store = store;
    }
    fromResponse(param) {
        let { licenses, ...pluginUpdate } = param;
        set(this, pluginUpdate);
        this.licenses = [];
        licenses == null ? void 0 : licenses.forEach((license)=>{
            this.licenses.push(new License(license, this));
        });
    }
}
_ts_decorate([
    observable
], PluginUpdate.prototype, "busy", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], PluginUpdate.prototype, "slug", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Array)
], PluginUpdate.prototype, "licenses", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], PluginUpdate.prototype, "hasInteractedWithFormOnce", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], PluginUpdate.prototype, "name", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], PluginUpdate.prototype, "needsLicenseKeys", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], PluginUpdate.prototype, "announcementsActive", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], PluginUpdate.prototype, "allowsAutoUpdates", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], PluginUpdate.prototype, "allowsTelemetry", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], PluginUpdate.prototype, "allowsNewsletter", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], PluginUpdate.prototype, "potentialNewsletterUser", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], PluginUpdate.prototype, "privacyProvider", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], PluginUpdate.prototype, "privacyPolicy", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], PluginUpdate.prototype, "accountSiteUrl", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], PluginUpdate.prototype, "additionalCheckboxes", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], PluginUpdate.prototype, "licenseKeyHelpUrl", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], PluginUpdate.prototype, "checkUpdateLink", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", typeof ResponseRoutePluginUpdatePatchErrorInvalidKeysData === "undefined" ? Object : ResponseRoutePluginUpdatePatchErrorInvalidKeysData)
], PluginUpdate.prototype, "invalidKeysError", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], PluginUpdate.prototype, "showBlogName", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], PluginUpdate.prototype, "showNetworkWideUpdateIssueNotice", void 0);
_ts_decorate([
    computed,
    _ts_metadata("design:type", Function),
    _ts_metadata("design:paramtypes", []),
    _ts_metadata("design:returntype", void 0)
], PluginUpdate.prototype, "licensedEntries", null);
_ts_decorate([
    computed,
    _ts_metadata("design:type", Function),
    _ts_metadata("design:paramtypes", []),
    _ts_metadata("design:returntype", void 0)
], PluginUpdate.prototype, "unlicensedEntries", null);
_ts_decorate([
    computed,
    _ts_metadata("design:type", Function),
    _ts_metadata("design:paramtypes", []),
    _ts_metadata("design:returntype", void 0)
], PluginUpdate.prototype, "noUsageEntries", null);
_ts_decorate([
    computed,
    _ts_metadata("design:type", Function),
    _ts_metadata("design:paramtypes", []),
    _ts_metadata("design:returntype", void 0)
], PluginUpdate.prototype, "modifiableEntries", null);
_ts_decorate([
    computed,
    _ts_metadata("design:type", Function),
    _ts_metadata("design:paramtypes", []),
    _ts_metadata("design:returntype", void 0)
], PluginUpdate.prototype, "isLicensed", null);
_ts_decorate([
    action,
    _ts_metadata("design:type", Function),
    _ts_metadata("design:paramtypes", [
        typeof Partial === "undefined" ? Object : Partial
    ]),
    _ts_metadata("design:returntype", void 0)
], PluginUpdate.prototype, "fromResponse", null);
export { PluginUpdate };
