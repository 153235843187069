import { RootStore } from "../store";
const HASH_HANDLER_PLUGIN_UPDATE_MODAL_PREFIX = "rpm-wp-client-plugin-update-";
/**
 * Listen to window hash links and open the license modal.
 */ function listenHashPluginUpdate() {
    const { hash } = window.location;
    if (hash.startsWith(`#${HASH_HANDLER_PLUGIN_UPDATE_MODAL_PREFIX}`)) {
        const slug = hash.substr(HASH_HANDLER_PLUGIN_UPDATE_MODAL_PREFIX.length + 1);
        RootStore.get.pluginUpdateStore.showInModal(slug);
        // Reset, should not be visible in URL
        window.location.hash = "";
    }
}
export { listenHashPluginUpdate, HASH_HANDLER_PLUGIN_UPDATE_MODAL_PREFIX };
