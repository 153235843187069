import { _ as _ts_decorate } from "@swc/helpers/_/_ts_decorate";
import { _ as _ts_metadata } from "@swc/helpers/_/_ts_metadata";
import { observable, flow, action } from "mobx";
import { PluginUpdate } from "../models";
import { request } from "../utils";
import { locationRestPluginUpdateGet } from "../wp-api";
class PluginUpdateStore {
    constructor(rootStore){
        this.busy = false;
        this.pluginUpdates = new Map();
        this.pluginUpdateFetchErrors = new Map();
        this.showInModal = flow(function*(slug) {
            this.modalPlugin = slug;
            try {
                yield this.fetchPluginUpdate(slug);
            } catch (e) {
                console.log(e);
                throw e;
            }
        });
        this.fetchPluginUpdate = flow(function*(slug) {
            this.busy = true;
            try {
                const result = yield request({
                    location: locationRestPluginUpdateGet,
                    params: {
                        slug
                    }
                });
                const pluginUpdate = new PluginUpdate(result, this);
                this.pluginUpdates.set(slug, pluginUpdate);
                this.pluginUpdateFetchErrors.delete(slug);
                return pluginUpdate;
            } catch (e) {
                console.log(e);
                this.pluginUpdateFetchErrors.set(slug, e);
                throw e;
            } finally{
                this.busy = false;
            }
        });
        this.rootStore = rootStore;
    }
    hideModal() {
        this.modalPlugin = undefined;
    }
}
_ts_decorate([
    observable
], PluginUpdateStore.prototype, "busy", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", String)
], PluginUpdateStore.prototype, "modalPlugin", void 0);
_ts_decorate([
    observable
], PluginUpdateStore.prototype, "pluginUpdates", void 0);
_ts_decorate([
    observable
], PluginUpdateStore.prototype, "pluginUpdateFetchErrors", void 0);
_ts_decorate([
    action,
    _ts_metadata("design:type", Function),
    _ts_metadata("design:paramtypes", []),
    _ts_metadata("design:returntype", void 0)
], PluginUpdateStore.prototype, "hideModal", null);
export { PluginUpdateStore };
