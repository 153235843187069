import { _ as _ts_decorate } from "@swc/helpers/_/_ts_decorate";
import { _ as _ts_metadata } from "@swc/helpers/_/_ts_metadata";
import { observable, set, runInAction, flow } from "mobx";
import { locationRestLicenseDelete, locationRestLicenseRetry, locationRestLicenseTelemetryGet } from "../wp-api";
import { request } from "../utils";
class License {
    constructor(license, store){
        this.busy = false;
        this.retry = flow(function*() {
            this.busy = true;
            try {
                const result = yield request({
                    location: locationRestLicenseRetry,
                    params: {
                        slug: this.store.slug,
                        blogId: this.blog
                    }
                });
                set(this, result);
            } catch (e) {
                console.log(e);
                throw e;
            } finally{
                this.busy = false;
            }
        });
        this.deactivate = flow(function*() {
            this.busy = true;
            try {
                const result = yield request({
                    location: locationRestLicenseDelete,
                    params: {
                        slug: this.store.slug,
                        blogId: this.blog
                    }
                });
                set(this, result);
            } catch (e) {
                console.log(e);
                throw e;
            } finally{
                this.busy = false;
            }
        });
        this.fetchTelemetryData = flow(function*() {
            this.busy = true;
            try {
                const result = yield request({
                    location: locationRestLicenseTelemetryGet,
                    params: {
                        slug: this.store.slug,
                        blogId: this.blog
                    }
                });
                this.telemetryData = result;
            } catch (e) {
                console.log(e);
                throw e;
            } finally{
                this.busy = false;
            }
        });
        runInAction(()=>set(this, license));
        this.store = store;
    }
}
_ts_decorate([
    observable
], License.prototype, "busy", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], License.prototype, "uuid", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], License.prototype, "blog", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], License.prototype, "host", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], License.prototype, "programmatically", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], License.prototype, "blogName", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], License.prototype, "installationType", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], License.prototype, "telemetryDataSharingOptIn", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], License.prototype, "code", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], License.prototype, "hint", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], License.prototype, "remote", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", Object)
], License.prototype, "noUsage", void 0);
_ts_decorate([
    observable,
    _ts_metadata("design:type", typeof ResponseRouteLicenseTelemetryGet === "undefined" ? Object : ResponseRouteLicenseTelemetryGet)
], License.prototype, "telemetryData", void 0);
export { License };
