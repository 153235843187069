import { RootStore } from "../store";
const CLICK_HANDLER_PLUGIN_UPDATE_MODAL_ATTRIBUTE = "data-rpm-wp-client-plugin-update";
/**
 * Listen to clicks on `<a data-rpm-wp-client-license=""` links and open the license modal.
 */ function listenPluginUpdateLinkClick() {
    document.addEventListener("click", (e)=>{
        var _e_target;
        const plugin = (_e_target = e.target) == null ? void 0 : _e_target.getAttribute(CLICK_HANDLER_PLUGIN_UPDATE_MODAL_ATTRIBUTE);
        if (plugin) {
            RootStore.get.pluginUpdateStore.showInModal(plugin);
            e.preventDefault();
        }
    });
}
export { listenPluginUpdateLinkClick, CLICK_HANDLER_PLUGIN_UPDATE_MODAL_ATTRIBUTE };
